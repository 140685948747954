import { combineReducers } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { LOGOUT } from "../constants/actionTypes";
import accounts from "./accounts";
import androidApps from "./androidApps";
import appVersion from "./appVersion";
import auth, { initialState as initialAuthState } from "./auth";
import buyerFulfilment from "./buyerFulfilment";
import buyerFulfilments from "./buyerFulfilments";
import buyerLayout from "./buyerLayout";
import calls from "./calls";
import campaign from "./campaign";
import cancellations from "./cancellations";
import cart from "./cart";
import carts from "./carts";
import cashbackRules from "./cashbackRules";
import chatData from "./chatData";
import checkout from "./checkout";
import component from "./component";
import contacts from "./contacts";
import couponsData from "./coupons";
import createCoupon from "./createCoupon";
import customerGroup from "./customerGroup";
import exchange from "./exchange";
import exchanges from "./exchanges";
import images from "./images";
import inventories from "./inventories";
import inventoryAdditionRequest from "./inventoryAdditionRequest";
import inventoryAdditionRequests from "./inventoryAdditionRequests";
import inventoryRules from "./inventoryRules";
import invoices from "./invoices";
import keyword from "./keyword";
import layout from "./layout";
import layoutRules from "./layoutRules";
import ledger from "./ledger";
import locationCluster from "./locationCluster";
import neighbourhoods from "./neighbourhoods";
import order from "./order";
import orders from "./orders";
import payment from "./payment";
import payments from "./payments";
import pickup from "./pickup";
import pickups from "./pickups";
import playAccounts from "./playAccounts";
import priceCluster from "./priceCluster";
import processPickup from "./processPickup";
import product from "./product";
import productRules from "./productRules";
import products from "./products";
import productSuggestions from "./productSuggestions";
import productTag from "./productTag";
import referralsData from "./referrals";
import refresh from "./refresh";
import release from "./release";
import reports from "./reports";
import sellerFulfilments from "./sellers/fulfilments";
import sellerOrders from "./sellers/orders";
import sellerProducts from "./sellers/products";
import shipmentProducts from "./sellers/shipmentProducts";
import sellerShipments from "./sellers/shipments";
import shipment from "./shipment";
import shipments from "./shipments";
import addressData from "./shipments/addressReducer";
import batchDetailsData from "./shipments/batchDetailsReducer";
import batchesData from "./shipments/batchesReducer";
import batchLocationsData from "./shipments/batchLocationsReducer";
import batchPlansData from "./shipments/batchPlansReducer";
import batchData from "./shipments/batchReducer";
import deliveryPersonDistanceData from "./shipments/deliveryPersonDistanceReducer";
import deliveryPersonData from "./shipments/deliveryPersonReducer";
import deliveryStaffData from "./shipments/deliveryStaffReducer";
import deliveryVendorData from "./shipments/deliveryVendorReducer";
import deliveryVendorsData from "./shipments/deliveryVendorsReducer";
import ndrData from "./shipments/ndrReducer";
import pickupsData from "./shipments/pickupsReducer";
import plansData from "./shipments/plansReducer";
import shipmentInstancesData from "./shipments/shipmentInstancesReducer";
import shipmentRouteInstancesData from "./shipments/shipmentRouteInstancesReducer";
import shipmentRoutePlanData from "./shipments/shipmentRoutePlanReducer";
import shipmentsData from "./shipments/shipmentsReducer";
import toursData from "./shipments/toursReducer";
import trackingData from "./shipments/trackingReducer";
import userVerificationsData from "./shipments/userVerificationsReducer";
import warehouseData from "./shipments/warehouseReducer";
import warehousesData from "./shipments/warehousesReducer";
import spotInstances from "./spotInstances";
import storefront from "./storefront";
import subscriptionPlans from "./subscriptionPlans";
import theme from "./theme";
import translations from "./translations";
import tripData from "./trip";
import tripsData from "./trips";
import uploads from "./uploads";
import user from "./user";
import users from "./users";
import walletData from "./wallet";
import wishlists from "./wishlists";

const appReducer = combineReducers({
  accounts,
  androidApps,
  appVersion,
  auth,
  buyerFulfilment,
  buyerFulfilments,
  calls,
  cancellations,
  cart,
  carts,
  campaign,
  chatData,
  checkout,
  customerGroup,
  exchange,
  exchanges,
  inventories,
  inventoryAdditionRequest,
  inventoryAdditionRequests,
  inventoryRules,
  invoices,
  keyword,
  layout,
  layoutRules,
  component,
  locationCluster,
  buyerLayout,
  ledger,
  order,
  orders,
  wishlists,
  payment,
  payments,
  translations,
  pickup,
  pickups,
  priceCluster,
  cashbackRules,
  processPickup,
  product,
  products,
  productRules,
  productSuggestions,
  refresh,
  release,
  theme,
  reports,
  productTag,
  shipment,
  shipmentProducts,
  shipments,
  sellerFulfilments,
  sellerOrders,
  sellerProducts,
  sellerShipments,
  storefront,
  uploads,
  user,
  users,
  tripsData,
  tripData,
  shipmentsData,
  batchesData,
  batchData,
  batchDetailsData,
  batchPlansData,
  pickupsData,
  deliveryStaffData,
  deliveryPersonData,
  warehousesData,
  warehouseData,
  batchLocationsData,
  deliveryVendorData,
  deliveryVendorsData,
  shipmentInstancesData,
  userVerificationsData,
  deliveryPersonDistanceData,
  shipmentRoutePlanData,
  toursData,
  plansData,
  shipmentRouteInstancesData, //Similar to shipmentRoutePlanData, without pagination. Duplication required because we need both in places like Assign LastMile
  trackingData,
  addressData,
  neighbourhoods,
  ndrData,
  contacts,
  playAccounts,
  createCoupon,
  couponsData,
  spotInstances,
  subscriptionPlans,
  referralsData,
  walletData,
  images
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    localStorage.clear();
    Cookies.remove("user_id", { domain: "namaste.business" });
    state = {
      auth: {
        ...initialAuthState,
        session: state.auth.session
      }
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
