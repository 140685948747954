import { createBrowserHistory } from "history";
import React from "react";
import { Router } from "react-horizontal-router-dom";
import { Provider } from "react-redux";

import App from "../src/routes/index.js";
import { configureAppStore } from "./store";

const history = createBrowserHistory();

// Create Redux store with state injected by the server
const store = configureAppStore(window.__PRELOADED_STATE__);

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

const RootContainer = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  );
};
export default RootContainer;
